<template>
	<figure id="logo">
		<img class="img-responsive" alt="Logo" :src="logo">
	</figure>
</template>

<script>
    import ImagemLogoFinerFoodsBranca from "@/assets/images/LogoOficialFinerFoods-Branca.png"
	export default {
		name: 'LogoFinerFoodsBranca',
		data: () => ({
            logo: ImagemLogoFinerFoodsBranca,
		})
	}
</script>

<style lang="scss">
	#logo{
		justify-content: center;
		align-items: center;
		display: flex;
		flex-wrap: wrap;
		margin: auto;
		max-width: 250px;
		margin-bottom: 10px;
		margin-top: 10px;
		img{
			width: 100%;
		}
	}
</style>