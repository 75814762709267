<template>
    <div id="loader">
        <div class="bg">
        </div>
        <div class="logo">
            <LogoFinerFoodsBranca/>
        </div>
    </div>
</template>

<script>
    import LogoFinerFoodsBranca from '@/components/LogoFinerFoodsBranca.vue'

	export default {
		name: 'Loader',
        components: {
            LogoFinerFoodsBranca
        },
		data: () => ({
			dialog_loading: true
		})
	}
</script>

<style lang="scss">
    #loader{
        position: fixed;
        background-color: #404040;
        width: 100vw;
        height: 100vh;
        bottom: 0;
        left: 0;
        z-index: 10;
        overflow: hidden;
        .bg{
            position: absolute;
            background-image: url('~@/assets/images/bg-loader.png');
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            filter: blur(4px);
            -webkit-filter: blur(4px);
            height: 100vh;
            width: 100vw;
            z-index: 100;
            top: 0;
            left: 0;
        }
        .logo{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 101;
            img{
                animation: fadein 2s infinite alternate;
            }
        }
        @keyframes fadein {
            from{ 
                opacity: 0; 
                transform: scale(1.1);
            }
            to{
                opacity: 1;
                transform: scale(1.4);
            }
        }
    }
</style>